<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('global.importation')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">

export default {
	name: 'ImportData',
	mixins: [],
	data () {
		return {
			swiper_tabs: [{
                id: 'horse',
				label: 'import.horse',
				active: true,
				href : 'ImportHorse',
			}]
		}
	},
	components: {
        HeaderTab: () => import('@/components/HeaderTab'),
	}
}

</script>